import React from "react";
import { PageProps, Link, graphql } from "gatsby";

import { Bio } from "~/components/Bio";
import { Layout } from "~/components/Layout";
import { SEO } from "~/components/SEO";
import { rhythm } from "../utils/typography";
import { ContactForm } from "~/components/FormComponents";

type Data = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const Contact = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Contact" />
      <ContactForm />
    </Layout>
  );
};
export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
